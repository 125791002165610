<template>
    <div>
        <a-form :form="form" @submit="handleSubmit">
            <a-row :gutter="20">
                <a-col :span="12">
                    <a-form-item class="travel-input-outer">
                        <label
                            :class="
                                form.getFieldValue(`company_name`)
                                    ? 'filled'
                                    : ''
                            "
                        >
                            Razão social
                        </label>

                        <a-input
                            class="travel-input"
                            placeholder="Razão social"
                            v-decorator="[
                                `company_name`,
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-icon slot="prefix" type="field-svg" />
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item class="travel-input-outer">
                        <label
                            :class="
                                form.getFieldValue(`trading_name`)
                                    ? 'filled'
                                    : ''
                            "
                        >
                            Nome Fantasia
                        </label>

                        <a-input
                            class="travel-input"
                            placeholder="Nome Fantasia"
                            v-decorator="[
                                `trading_name`,
                                {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Obrigatório',
                                        },
                                    ],
                                },
                            ]"
                        >
                            <a-icon slot="prefix" type="field-svg" />
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col :span="12">
                    <a-form-item class="travel-input-outer">
                        <label
                            :class="form.getFieldValue(`cnpj`) ? 'filled' : ''"
                        >
                            CNPJ
                        </label>

                        <a-input
                            class="travel-input"
                            placeholder="CNPJ"
                            v-mask="'##.###.###/####-##'"
                            v-decorator="[`cnpj`]"
                        >
                            <a-icon slot="prefix" type="field-svg" />
                        </a-input>
                    </a-form-item>
                </a-col>

                <a-col class="a-center mt-20" :span="24">
                    <a-button
                        size="large"
                        type="primary"
                        :loading="loadingCreateCompany"
                        html-type="submit"
                    >
                        CRIAR EMPRESA
                    </a-button>
                </a-col>
            </a-row>
        </a-form>
    </div>
</template>

<script>
export default {
    name: "EditCompanyDefaultData",
    props: {
        edit: Boolean,
        companyId: Number,
    },
    components: {},
    data() {
        return {
            form: this.$form.createForm(this),
            loadingCreateCompany: false,
            airline: {},
        };
    },
    mounted() {

    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                values.user_id = this.$store.state.userData.id;
                console.log(err, values);
                if (!err) {
                    this.loadingCreateCompany = true;

                    this.$http
                        .post("/company/create", values)
                        .then(({ data }) => {
                            this.$message.success(data.message);
                            this.loadingCreateCompany = false;
                            this.$emit("listCompanies", true);
                        })
                        .catch(({ response }) => {
                            this.$message.error(response.data.message);
                            this.loadingCreateCompany = false;
                        });
                }
            });
        },
    },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue(`company_name`)
                                ? 'filled'
                                : ''},[_vm._v("\n                        Razão social\n                    ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            `company_name`,
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]),expression:"[\n                            `company_name`,\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Razão social"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue(`trading_name`)
                                ? 'filled'
                                : ''},[_vm._v("\n                        Nome Fantasia\n                    ")]),_vm._v(" "),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            `trading_name`,
                            {
                                rules: [
                                    {
                                        required: true,
                                        message: 'Obrigatório',
                                    },
                                ],
                            },
                        ]),expression:"[\n                            `trading_name`,\n                            {\n                                rules: [\n                                    {\n                                        required: true,\n                                        message: 'Obrigatório',\n                                    },\n                                ],\n                            },\n                        ]"}],staticClass:"travel-input",attrs:{"placeholder":"Nome Fantasia"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:_vm.form.getFieldValue(`cnpj`) ? 'filled' : ''},[_vm._v("\n                        CNPJ\n                    ")]),_vm._v(" "),_c('a-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"},{name:"decorator",rawName:"v-decorator",value:([`cnpj`]),expression:"[`cnpj`]"}],staticClass:"travel-input",attrs:{"placeholder":"CNPJ"}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('a-col',{staticClass:"a-center mt-20",attrs:{"span":24}},[_c('a-button',{attrs:{"size":"large","type":"primary","loading":_vm.loadingCreateCompany,"html-type":"submit"}},[_vm._v("\n                    CRIAR EMPRESA\n                ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }